import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivityConfigConfig, ActivityWithRelations } from "../../types/Activity/Activity";
import ActivitiesState from "../../types/ActivitiesState";
import Page from "../../types/Page/Page";
import Question from "../../types/Question/Question";

const initialState: ActivitiesState = {
  items: [],
  page: 1,
  fetchActivities: true,
  fetchActivitiesFailed: false,
  addActivity: false,
  addActivitiesFailed: false,
  deleteActivity: false,
  deleteActivityFailed: false,
  updateActivity: false,
  updateActivityFailed: false,
  addActivityScalesGroup: false,
  addActivityScalesGroupFailed: false,
  deleteActivityScalesGroup: false,
  deleteActivityScalesGroupFailed: false,
  addPage: false,
  addPageFailed: false,
  deletePage: false,
  deletePageFailed: false,
  updatePage: false,
  updatePageFailed: false,
  addQuestion: false,
  addQuestionFailed: false,
  updateQuestion: false,
  updateQuestionFailed: false,
  deleteQuestion: false,
  deleteQuestionFailed: false,
  updateConfig: false,
  updateConfigFailed: false,
  cloneActivity: false,
  cloneActivityFailed: false,
};

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setActivitiesPage: (state, action) => {
      state.page = action.payload;
    },
    loadActivities: (state) => {
      state.fetchActivities = true;
    },
    loadActivitiesFailed: (state) => {
      state.fetchActivities = false;
      state.fetchActivitiesFailed = true;
    },
    loadActivitiesSuccess: (state, action) => {
      state.items = action.payload;
      state.fetchActivities = false;
    },
    addActivity: (state) => {
      state.addActivity = true;
    },
    addActivityFailed: (state) => {
      state.addActivity = false;
      state.addActivitiesFailed = true;
    },
    addActivitySuccess: (state, action) => {
      state.addActivity = false;
      state.items.push(action.payload);
    },
    deleteActivity: (state) => {
      state.deleteActivity = true;
    },
    deleteActivityFailed: (state) => {
      state.deleteActivity = false;
      state.deleteActivityFailed = true;
    },
    deleteActivitySuccess: (state, action) => {
      state.deleteActivity = false;
      state.items = state.items.filter((activity) => {
        return activity.id !== action.payload;
      });
    },
    updateActivity: (state) => {
      state.updateActivity = true;
    },
    updateActivityFailed: (state) => {
      state.updateActivity = false;
      state.updateActivityFailed = true;
    },
    updateActivitySuccess: (state, action) => {
      const updateActivity = action.payload;
      const activityIndex = state.items.findIndex((activity) => activity.id === updateActivity.id)!;

      state.items[activityIndex] = updateActivity;

      state.updateActivity = false;
    },
    cloneActivity: (state) => {
      state.cloneActivity = true;
    },
    cloneActivityFailed: (state) => {
      state.cloneActivity = false;
      state.cloneActivityFailed = true;
    },
    cloneActivitySuccess: (state, action) => {
      const updateActivity = action.payload;
      const activityIndex = state.items.findIndex((activity) => activity.id === updateActivity.id)!;

      state.items[activityIndex] = updateActivity;

      state.cloneActivity = false;
    },
    addActivityScalesGroup: (state) => {
      state.addActivityScalesGroup = true;
    },
    addActivityScalesGroupFailed: (state) => {
      state.addActivityScalesGroup = false;
      state.addActivityScalesGroupFailed = true;
    },
    addActivityScalesGroupSuccess: (state, action) => {
      const { scalesGroup, activityId } = action.payload;
      const activity = state.items.find((activity) => activity.id === activityId)!;

      state.addActivityScalesGroup = false;
      activity.scalesGroups.push(scalesGroup);
    },
    deleteActivityScalesGroup: (state) => {
      state.deleteActivityScalesGroup = true;
    },
    deleteActivityScalesGroupFailed: (state) => {
      state.deleteActivityScalesGroup = false;
      state.deleteActivityScalesGroupFailed = true;
    },
    deleteActivityScalesGroupSuccess: (state, action) => {
      const { scalesGroup, activityId } = action.payload;
      const removedScalesGroup = scalesGroup;

      const activity = state.items.find((activity) => activity.id === activityId)!;

      state.deleteActivityScalesGroup = false;

      activity.scalesGroups = activity.scalesGroups.filter((scalesGroup) => {
        return scalesGroup.id !== removedScalesGroup.id;
      });
    },
    addPage: (state) => {
      state.addPage = true;
    },
    addPageFailed: (state) => {
      state.addPage = false;
      state.addPageFailed = true;
    },
    addPageSuccess: (state, action: PayloadAction<Page>) => {
      const page = action.payload;
      const activity = state.items.find((activity) => activity.id === page.activityId)!;

      state.addPage = false;
      activity.pages.push(page);
    },
    deletePage: (state) => {
      state.deletePage = true;
    },
    deletePageFailed: (state) => {
      state.deletePage = false;
      state.deletePageFailed = true;
    },
    deletePageSuccess: (state, action: PayloadAction<Page>) => {
      const pageRemoved = action.payload;

      const activity = state.items.find((activity) => activity.id === pageRemoved.activityId)!;

      state.deletePage = false;

      activity.pages = activity.pages.filter((page) => {
        return page.id !== pageRemoved.id;
      });
    },
    updatePage: (state) => {
      state.updatePage = true;
    },
    updatePageFailed: (state) => {
      state.updatePage = false;
      state.updatePageFailed = true;
    },
    updatePageSuccess: (state, action: PayloadAction<Page>) => {
      const updatedPage = action.payload;

      const activity = state.items.find((activity) => activity.id === updatedPage.activityId)!;
      const pageIndex = activity.pages.findIndex((page) => page.id === updatedPage.id)!;

      activity.pages[pageIndex] = {
        ...activity.pages[pageIndex],
        ...updatedPage,
      };

      state.updatePage = false;
    },
    addQuestion: (state) => {
      state.addQuestion = true;
    },
    addQuestionFailed: (state) => {
      state.addQuestion = false;
      state.addQuestionFailed = true;
    },
    addQuestionSuccess: (state, action: PayloadAction<Question>) => {
      const question = action.payload;
      const activity = state.items.find((activity) => activity.id === question.activityId)!;

      state.addQuestion = false;
      activity.questions.push(question);
    },
    deleteQuestion: (state) => {
      state.deleteQuestion = true;
    },
    deleteQuestionFailed: (state) => {
      state.deleteQuestion = false;
      state.deleteQuestionFailed = true;
    },
    deleteQuestionSuccess: (state, action: PayloadAction<Page>) => {
      const questionRemoved = action.payload;

      const activity = state.items.find((activity) => activity.id === questionRemoved.activityId)!;

      state.deleteQuestion = false;

      activity.questions = activity.questions.filter((question) => {
        return question.id !== questionRemoved.id;
      });
    },
    updateQuestion: (state) => {
      state.updateQuestion = true;
    },
    updateQuestionFailed: (state) => {
      state.updateQuestion = false;
      state.updateQuestionFailed = true;
    },
    updateQuestionSuccess: (state, action: PayloadAction<Question>) => {
      const updatedQuestion = action.payload;

      const activity = state.items.find((activity) => activity.id === updatedQuestion.activityId)!;
      const questionIndex = activity.questions.findIndex(
        (question) => question.id === updatedQuestion.id
      )!;

      activity.questions[questionIndex] = {
        ...activity.questions[questionIndex],
        ...updatedQuestion,
      };

      state.updateQuestion = false;
    },
    updateConfig: (state) => {
      state.updateConfig = true;
    },
    updateConfigFailed: (state) => {
      state.updateConfig = false;
      state.updateConfigFailed = true;
    },
    updateConfigSuccess: (state, action: PayloadAction<ActivityConfigConfig>) => {
      const updatedConfig = action.payload;

      const activityIndex = state.items.findIndex(
        (activity) => activity.id === updatedConfig.activityId
      )!;

      state.items[activityIndex].config = updatedConfig;

      state.updateQuestion = false;
    },
    setQuestions: (state, action) => {
      let currentActivity = state.items.find(
        (activity) => activity.id === action.payload.activityId
      )!;

      currentActivity.questions = action.payload.questions;
    },
    setQuestionPageId: (state, action) => {
      let currentActivity = state.items.find(
        (activity: ActivityWithRelations) => activity.id === action.payload.activityId
      )!;

      let currentQuestion = currentActivity.questions.find(
        (question) => question.id === action.payload.questionId
      )!;

      currentQuestion.pageId = action.payload.pageId;
    },
    addScalesGroup: (state, action) => {
      const { activityId, scalesGroupId } = action.payload;

      let currentActivity = state.items.find(
        (activity: ActivityWithRelations) => activity.id === activityId
      )!;

      currentActivity.scalesGroups.push(scalesGroupId);
    },
    deleteScalesGroup: (state, action) => {
      const { activityId, scalesGroupId, deleteScalesIds } = action.payload;

      let currentActivity = state.items.find(
        (activity: ActivityWithRelations) => activity.id === activityId
      )!;

      currentActivity.questions.forEach((q) => {
        q.options &&
          q.options.forEach((v) => {
            v.scales = v.scales && v.scales.filter((s) => !deleteScalesIds.includes(s.id));
          });
      });

      currentActivity.scalesGroups = currentActivity.scalesGroups.filter(
        (g) => g !== scalesGroupId
      );
    },
  },
});

const { actions, reducer } = activitiesSlice;

export const {
  setActivitiesPage,
  loadActivities,
  loadActivitiesFailed,
  loadActivitiesSuccess,
  addActivity,
  addActivityFailed,
  addActivitySuccess,
  deleteActivity,
  deleteActivityFailed,
  deleteActivitySuccess,
  updateActivity,
  updateActivityFailed,
  updateActivitySuccess,
  addActivityScalesGroup,
  addActivityScalesGroupFailed,
  addActivityScalesGroupSuccess,
  deleteActivityScalesGroup,
  deleteActivityScalesGroupFailed,
  deleteActivityScalesGroupSuccess,
  cloneActivity,
  cloneActivitySuccess,
  cloneActivityFailed,

  // Pages
  addPage,
  addPageFailed,
  addPageSuccess,
  deletePage,
  deletePageFailed,
  deletePageSuccess,
  updatePage,
  updatePageFailed,
  updatePageSuccess,

  // Questions
  addQuestion,
  addQuestionFailed,
  addQuestionSuccess,
  deleteQuestion,
  deleteQuestionFailed,
  deleteQuestionSuccess,
  updateQuestion,
  updateQuestionFailed,
  updateQuestionSuccess,

  // Config
  updateConfig,
  updateConfigSuccess,
  updateConfigFailed,

  setQuestions,
  setQuestionPageId,
} = actions;

export default reducer;
